.order-card {
  border: 1px solid #bbb;
  padding: 8px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
}

.order-card-content {
  margin: 0;
  padding: 0;
}

.time-indicator {
  border-style: solid;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  position: absolute;
  right: -15px;
  top: -15px;
  background-color: white;
  text-align: center;
  cursor: pointer;
}


.order-card-content::-webkit-scrollbar {
  display: none;
}

.order-customer-details {
  font-size: 12px;
  text-align: center;
}

.order-card-header p {
  text-align: center;
  text-decoration: underline;
}

.divider {
  border-top: 1px solid #bbb;
  padding: 0px;
  margin: 0px;
}

.order-card-actions {
  text-align: center;
}

.order-card-actions button {
  margin: 4px;
}

.footer-logo {
  max-width: 20%;
}

.spacer {
  flex-grow: 1;
}
