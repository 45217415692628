.preview-img {
  height: 20px;
  position: absolute;
  right: 30px;
  top: 10px;
}

@media only screen and (max-width: 600px) {
  .preview-img {
    display: none;
  }
}
