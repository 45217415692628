.restaurant-cards-container {
  max-width: 500px;
  max-height: 60px;
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  padding-bottom: 8px;
}

.restaurant-cards-container::-webkit-scrollbar {
  display: none;
}

.carousel-button {
  cursor: pointer;
  margin-left: 6px;
  margin-top: 15px;
  margin-right: 6px;
}

.carousel-button span i {
  transform: scale(2);
  margin: auto;
  display: block;
}

.carousel-item {
  user-select: none;
  white-space: nowrap;
  border-radius: 30px;
  border-style: solid;
  border-color: 'black !important';
  border-width: medium;
  padding: 4px 10px 4px 10px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  position: relative;
  background-color: white;
  color: black;
}

.carousel-item-icon {
  position: absolute;
  max-width: 20px;
  left: calc(50%);
  transform: translate(-50%);
  top: calc(-55%);
}

@media screen and (max-width: 1023px) {
  .carousel-item-icon {
    display: none;
  }
}
