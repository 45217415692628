.grid-container {
  display: flex;
  flex-direction: column;
  touch-action: none;
  margin: 1rem auto;
}

.title-span {
  font-size: 0.8rem;
  padding-left: 8px;
  color: grey;
}

.grid-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
}

.dropzone {
  transition: transform 0.5s linear;
  -webkit-transition: -webkit-transform 0.5s linear;
  flex-grow: 1;
  border: 1px solid black;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
}

.dropzone.right {
  height: 700px;
}

.grid-item {
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;
}

.grid-category-item {
  background-color: white;
  border-style: solid;
  border-color: #cead29;
  border-radius: 20px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  user-select: none;
  font-family: Arial, Helvetica, sans-serif;
}

.grid-item-content {
  min-height: 115px;
  box-sizing: border-box;
  background-color: white;
  border-style: solid;
  border-color: #cead29;
  display: flex;
  flex-direction: row;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  border-radius: 20px;
  padding: 5px;
}

.popover-content p {
  cursor: pointer;
  padding: 3px;
}

.popover-content p:hover {
  cursor: pointer;
  padding: 3px;
  color: #cead29;
}

.optionContainer li:hover,
.optionContainer .highlight {
  background: #cead29;
  color: #fff;
}
