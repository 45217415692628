.cards {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  column-gap: 8rem;
  row-gap: 5rem;
}

@media (min-width: 100px) {
  .cards {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (min-width: 400px) {
  .cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 900px) {
  .cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
