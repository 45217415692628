.collapsible {
  display: none;
}
.collapsible.active {
  display: block;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rotating-box {
  height: 18px;
  width: 16px;
  transition: transform 0.3s ease-in-out;
}
.rotate {
  transform-origin: center;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
